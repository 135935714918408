<template>
  <div class="main">
    <tobs ref="citynews"></tobs>
    <div class="content">
      <div class="chooseBox c">
        <div style="padding: 10px 20px">
          <p>首页>求职信息</p>
          <div class="workType">
            <span class="leftSpan">工种</span>
            <div class="workTypeBox">
              <span
                class="workTypeItem"
                @click="getworkType(item, index)"
                :class="index == workTypeIndex ? 'blue' : ''"
                v-for="(item, index) in tradeTypeList"
                :key="index"
                >{{ item.title }}</span
              >
            </div>
          </div>
          <el-divider></el-divider>
          <div class="province">
            <span class="leftSpan">省份</span>
            <div class="provinceBox">
              <span
                class="provinceItem"
                @click="getProvince(item, index)"
                :class="index == provinceIndex ? 'blue' : ''"
                v-for="(item, index) in provincialList"
                :key="index"
                >{{ item.SHORT_NAME }}</span
              >
            </div>
          </div>
          <div class="city">
            <span class="leftSpan">城市</span>
            <div class="cityBox">
              <span
                class="cityItem"
                @click="getCity(item, index)"
                :class="index == cityIndex ? 'blue' : ''"
                v-for="(item, index) in cityList"
                :key="index"
                >{{ item.SHORT_NAME }}</span
              >
            </div>
          </div>
          <div class="personnel">
            <span class="leftSpan">人员构成</span>
            <div class="personnelBox">
              <span
                class="personnelItem"
                @click="getPersonnel(item, index)"
                :class="index == personnelIndex ? 'blue' : ''"
                v-for="(item, index) in jobUserType"
                :key="index"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <el-divider></el-divider>
          <div class="intelligence">
            <span class="leftSpan">筛选条件</span>
            <div class="intelligenceBox">
              <el-select
                v-model="eduValue"
                placeholder="学历"
                size="mini"
                :style="cssVars"
                @change="getJobEdu"
              >
                <el-option
                  v-for="item in jobEdu"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="ageValue"
                placeholder="年龄"
                size="mini"
                :style="cssVars"
                @change="getAgeList()"
              >
                <el-option
                  v-for="(item, index) in ageList"
                  :key="index"
                  :label="item.name"
                  :value="index"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="expValue"
                placeholder="工作经验"
                size="mini"
                :style="cssVars"
                @change="getJobExp()"
              >
                <el-option
                  v-for="(item, index) in exp"
                  :key="index"
                  :label="item.name"
                  :value="index"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="certificateValue"
                placeholder="智能选择"
                size="mini"
                :style="cssVars"
                @change="getIntelligence()"
              >
                <el-option
                  v-for="(item, index) in certificateList"
                  :key="index"
                  :label="item.name"
                  :value="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="listBox c">
        <div class="listLeft">
          <div>
            <div
              class="personalList"
              v-for="(item, index) in jobInfoList"
              :key="index"
            >
              <div class="personalListTop">
                <div class="left">
                  <img :src="item.avatar ? $imgUrl(item.avatar) : srcImgUrl1" :onerror="$global.srcImgUrl" alt="" />
                  <div class="name">
                    <div class="nameTop">
                      <span class="peopleName">{{ $processName(item.name) }}</span>
                      <span
                        style="
                          color: #ff8d19;
                          font-size: 12px;
                          margin-right: 18px;
                          display: flex;
                        "
                      >
                        <i class="iconfont icon-shiming"></i>
                        已实名
                      </span>
                      <span
                        v-if="
                          item.degree_certificate ||
                          item.qualification_certificate ||
                          item.title_certificate
                        "
                        style="
                          color: #00c750;
                          font-size: 12px;
                          margin-right: 24px;
                          display: flex;
                        "
                      >
                        <i class="iconfont icon-fabuzhengshu"></i>
                        技能认证
                      </span>
                      <span class="pay">{{ getPay(item.expectSalary) }}</span>
                    </div>
                    <div class="nameBottom">
                      <span>{{ item.sex == 1 ? '男' : '女' }}</span>
                      <el-divider direction="vertical"></el-divider>
                      <span>{{ item.age ? item.age : '' }}岁</span>
                      <el-divider direction="vertical"></el-divider>
                      <span>{{ item.exp ? getExp(item.exp) : '' }}</span>
                      <el-divider direction="vertical"></el-divider>
                      <span>{{ item.title ? getJobUserType(item.title) : '' }}</span>
                      <el-divider direction="vertical"></el-divider>
                      <span>{{ item.edu ? getEdu(item.edu) : '' }}</span>
                    </div>
                  </div>
                </div>
                <div class="right">
                  <!-- <div class="contact" @click="toDetails(item.id)">立即联系</div> -->
                  <el-button
                    type="primary"
                    size="medium"
                    @click="toDetails(item)"
                    >立即联系</el-button
                  >
                  <div class="city">
                    <i
                      class="iconfont icon-dingxiang"
                      style="margin-right: 20px; font-size: 18px"
                    ></i>
                    <span
                      >
                      <span v-if="item.province">{{ getProvinceForId(item.province) }} ·</span>
                      {{
                        item.city ? getCityForId(item.city) : ''
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="personalListBottom">
                <div class="tag">
                  <el-tag size="small">{{
                    getExpectPostForId(tradeTypeList, item.expectPost)
                  }}</el-tag>
                </div>
                <span class="introduce">{{ item.my_intro }}</span>
              </div>
            </div>
          </div>

          <el-empty v-if="total == 0" description="空空如也"></el-empty>

          <div class="pagination">
            <el-pagination
              :background="true"
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              @current-change="chengePagination"
            >
            </el-pagination>
          </div>
        </div>
        <div class="listRight">
          <div class="seeList">
            <div class="title">看过的人才</div>
            <div class="peopleList">
              <div class="peopleItem" v-for="(item, index) in readInfo" :key="index" @click="toDetails(item)">
                <img :src="item.avatar? $imgUrl(item.avatar) : srcImgUrl1" :onerror="$global.srcImgUrl" alt="" />
                <div class="peopleInfo">
                  <div class="name">
                    <span>{{ item.name ? $processName(item.name) : '' }}</span>
                    <span>{{ item.exp ? getExp(item.exp) : '' }}</span>
                  </div>
                  <div class="tag">
                    <el-tag size="small">{{
                      item.expectPost
                        ? getExpectPostForId(tradeTypeList, item.expectPost)
                        : ''
                    }}</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="recommend">
            <div class="title">职位推荐</div>
            <div class="peopleList">
              <div class="peopleItem" v-for="(item, index) in recommendList" :key="index" style="cursor: pointer;" @click="toDatumInfo(item)">
                <div class="peopleInfo">
                  <div class="name" style="margin-bottom:12px;">
                    <span>{{ item.name }}</span>
                    <span style="color: #ff8d19;">{{ $dictInfo.getPay(item.pay) }}</span>
                  </div>
                  <div class="tag">
                    <el-tag size="small">{{ item.city ? $dictInfo.getCityForId(item.city) :'' }} | {{ item.exp ? $dictInfo.getExp(item.exp) : '' }} |
                            {{ item.edu ? $dictInfo.getEdu(item.edu) : '' }}</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foots style="margin-top: 100px"></foots>
  </div>
</template>

<script>
import Tobs from '@/components/hometitle/hometitle.vue'
import Foots from '@/components/foot/foot.vue'
import { getProvincial, getCity } from '@/api/index/index'
import {
  jobInfoPage,
  queryJobInfoPage,
} from '@/api/jobInformation/jobInformation'
import { jobPostType, jobPostSearch } from '@/api/position/position'
import { readInfo } from '@/utils/readInfo.js'
export default {
  name: '',
  data() {
    return {
      srcImgUrl1: require("../../assets/home/people.png"),
      dict: [],
      exp: [], //工作经验
      jobEdu: [], //学历列表
      jobUserType: [], //人员构成
      provincialList: [], //省份
      cityList: [], //城市
      jobPay: [], //薪资
      tradeTypeList: [], //工种列表
      cityParams: {
        PROVINCE_CODE: '',
      },
      certificateList: [{ name: '全部' }, { name: '证书' }],
      workTypeIndex: 0,
      provinceIndex: 0,
      cityIndex: 0,
      eduIndex: 0,
      expIndex: 0,
      personnelIndex: 0,
      intelligenceIndex: 0,
      total: 0,
      jobInfoList: [], //求职列表
      params: {
        pageNo: 1,
        pageSize: 10,
        auditStatus: 2,
        expectPost: '',
        province: '420000',
        city: '',
        title: '',
        certificate: '', //0是没有1是有
      },
      showProvinceList: [], //省份列表
      showCityList: [], //城市列表
      ageList: [],
      ageIndex: 0,
      eduValue: '',
      color: '#1592ea',
      expValue: '',
      ageValue: '',
      certificateValue: '',
      readInfo:[],
      recommendList:[]
    }
  },
  watch: {},
  props: {},
  components: {
    Tobs,
    Foots,
  },
  computed: {
    cssVars () {
      return {
         "--color": this.color,
        }
  }
  },
  created() {
    this.dict = JSON.parse(localStorage.getItem('dict'))
    if(!this.dict){
      this.$global.getDictInfo().then(res=>{
        this.dict = res.data
        this.initData()
      })
    }else{
      this.initData()
    }

    if(localStorage.getItem("readInfo")){
      this.readInfo = JSON.parse(localStorage.getItem("readInfo"))
    }
    this.getProvincialList()
    this.getCityList()
    this.getJobInfoPage()
    this.getJobTradeType()
    this.recommend()
  },
  mounted() {},
  methods: {
    initData(){
      this.exp = this.dict.filter(i => i.code == 'job_exp')[0].children
    this.jobEdu = this.dict.filter(i => i.code == 'job_edu')[0].children
    this.ageList = this.dict.filter(i => i.code == 'job_user_age')[0].children
    this.jobUserType = this.dict
      .filter(i => i.code == 'job_user_type')[0]
      .children.filter(i => i.code != 2)
    this.jobPay = this.dict.filter(i => i.code == 'job_pay')[0].children
    if(this.showProvinceList.length > 0 && this.showCityList.length > 0){
      this.showProvinceList = JSON.parse(localStorage.getItem('provincialList'))
      this.showCityList = JSON.parse(localStorage.getItem('cityList'))
    }else{
      this.getProvincialCity()
    }
    this.jobUserType.unshift({ name: '全部', code: '' })
    this.jobEdu.unshift({ name: '全部', code: '' })
    this.exp.unshift({ name: '全部', code: '' })
    this.ageList.unshift({ name: '全部', code: '' })
    },
    getProvincialCity(){
      this.$global.getProvincialInfo().then((res) => {
         this.showProvinceList = res.data
      });

      this.$global.getCityInfo().then((res) => {
         this.showCityList = res.data;
      });
    },
    async recommend(){
      let obj = {
        grounding: 'Y',
        auditStatus: 2,
        pageNo: 1,
        pageSize: 10
      }
      let res = await jobPostSearch(obj)
      let data =  res.data.rows.sort((a, b) => Math.random() > 0.5 ? 1 : -1);
      this.recommendList = data
    },
    // 查看职位详情
    toDatumInfo(item) {
      this.$router.push({
        path: "/datum",
        query: { id: item.id },
      })
    },
    getPay(code) {
       if(this.jobPay.length >0 && code){
        return this.jobPay.filter(i => i.code == code)[0].name
      }else{
        return ''
      }
    },
    // 回显工种
    getExpectPostForId(list, id) {
      var title = ''
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          title = list[i].title
          break
        } else if (list[i].children && list[i].children.length > 0) {
          title = this.getExpectPostForId(list[i].children, id)
          if (title) return title
        }
      }
      // console.log(title)
      return title
    },
    //工种
    getJobTradeType() {
      jobPostType({ code: 'trade_type' }).then(res => {
        this.hasChildren(res.data[0].children)
        console.log(this.tradeTypeList)
        this.tradeTypeList.unshift({ title: '全部' })
      })
    },
    // 级联选择器递归判断children有没有值
    hasChildren(list) {
      for (let i = 0; i < list.length; i++) {
        list[i]
        if (list[i].children.length > 0) {
          this.hasChildren(list[i].children)
        } else {
          this.tradeTypeList.push(list[i])
        }
      }
      // list.forEach(item => {
      //   if (item.children.length > 0) {
      //     this.hasChildren(item.children)
      //   } else {
      //     item.children = null
      //   }
      // })
      // return list
    },
    // 显示人员构成
    getJobUserType(val) {
      return this.jobUserType.filter(i => i.code == val)[0].name
    },
    // 显示工作经验
    getExp(val) {
      return this.exp.filter(i => i.code == val)[0].name
    },
    // 显示学历
    getEdu(val) {
      return this.jobEdu.filter(i => i.code == val)[0].name
    },
    // 招聘列表
    getJobInfoPage() {
      queryJobInfoPage(this.params).then(res => {
        this.jobInfoList = res.data.rows
        this.total = res.data.totalRows
      })

      // jobInfoPage(this.params).then(res => {
      //   this.jobInfoList = res.data.rows
      //   this.total = res.data.totalRows
      // })
    },
    // 招聘列表分页
    chengePagination(cur) {
      this.params.pageNo = cur
      this.getJobInfoPage()
      document.documentElement.scrollTop = 0;
    },
    // 回显省份
    getProvinceForId(id) {
      if(this.showProvinceList.length > 0 && id){
      return this.showProvinceList.filter(i => i.PROVINCE_CODE == id)[0]
        .SHORT_NAME
      }else{
        return ''
      }
    },
    // 回显城市
    getCityForId(id) {
      if(this.showCityList.length > 0 && id){
        return this.showCityList.filter(i => i.CITY_CODE == id)[0].SHORT_NAME
      }else{
        return ''
      }

    },
    getProvincialList() {
      getProvincial().then(res => {
        this.provincialList = res.data
        this.provincialList.unshift({ SHORT_NAME: '全部' })
      })
    },
    getCityList() {
      getCity(this.cityParams).then(res => {
        this.cityList = []
        if(this.cityParams.PROVINCE_CODE){
           this.cityList = res.data
        }
        this.cityList.unshift({ SHORT_NAME: '全部' })
      })
    },
    getworkType(item, index) {
      if (item.title == '全部') {
        this.params.expectPost = ''
        this.getJobInfoPage()
      } else {
        this.params.expectPost = item.id
        this.getJobInfoPage()
      }
      this.workTypeIndex = index
    },
    getProvince(item, index) {
      console.log(item)
      this.params.pageNo = 1
      if (item.SHORT_NAME == '全部') {
        this.params.province = ''
        this.params.city = ''
        this.getJobInfoPage()
      } else {
        this.params.province = item.PROVINCE_CODE
        this.params.city = ''
        this.getJobInfoPage()
      }
      this.provinceIndex = index
      this.cityParams.PROVINCE_CODE = item.PROVINCE_CODE
      this.cityIndex = 0
      this.getCityList()
    },
    getCity(item, index) {
      console.log(item)
      this.params.pageNo = 1
      if (item.SHORT_NAME == '全部') {
        this.params.city = ''
        this.getJobInfoPage()
      } else {
        this.params.city = item.CITY_CODE
        this.getJobInfoPage()
      }
      this.cityIndex = index
    },
    getJobEdu(e) {
        if(this.eduValue === ''){
          this.params.edu = ''
        }else{
          this.params.edu = this.eduValue
        }
        this.getJobInfoPage()
    },
    getJobExp() {
      if(this.expValue===0){
          this.params.exp = ''
        }else{
          this.params.exp = this.expValue
        }
        this.getJobInfoPage()
    },
    getAgeList() {
      if(this.ageValue===0){
          this.params.ageIndex = ''
        }else{
          this.params.ageIndex = this.ageValue
        }
        this.getJobInfoPage()
    },
    getPersonnel(item, index) {
      console.log(item)
      if (item.name == '全部') {
        this.params.title = ''
        this.personnelIndex = ''
        this.getJobInfoPage()
      } else {
        this.params.title = item.code
        this.personnelIndex = index
        this.getJobInfoPage()
      }
    },
    getIntelligence() {
      if(this.certificateValue===0){
          this.params.certificate = ''
        }else{
          this.params.certificate = this.certificateValue
        }
        this.getJobInfoPage()
    },
    toDetails(item) {
      this.readInfo = readInfo(item)
      this.$router.push({
        path: '/jobInformationDetails',
        query: {
          id: item.id,
          degreeId: item.degree_certificate,
          qualificationId: item.qualification_certificate,
          titleId: item.title_certificate,
        },
      })
    }
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.c {
  width: 1200px;
  margin: 0 auto;
}

.content {
  margin-top: 110px;
}

.blue {
  color: #1592ea !important;
}

.pagination {
  text-align: center;
}

  ::v-deep input::-webkit-input-placeholder {
    color: #999;
  }
  ::v-deep  input::-moz-input-placeholder {
    color: #999;
  }
  ::v-deep  input::-ms-input-placeholder {
    color: #999;
  }

::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}

.chooseBox {
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  p {
    color: #999;
    font-size: 14px;
  }
  .el-divider {
    border-top: 2px dashed #ccc;
    background-color: #fff;
    margin: 0;
  }
  .workType,
  .province,
  .city,
  .personnel,
  .exp,
  .age,
  .intelligence {
    display: flex;
    .leftSpan {
      width: 70px;
      text-align-last: justify;
      position: relative;
      padding-right: 6px;
      color: #999;
      margin: 10px 0;
      font-size: 14px;
    }
    .leftSpan::after {
      position: absolute;
      right: 0;
      content: ':';
    }
  }
  .workTypeBox,
  .provinceBox,
  .cityBox,
  .personnelBox,
  .expBox,
  .ageBox,
  .intelligenceBox {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .workTypeItem,
    .provinceItem,
    .cityItem,
    .personnelItem,
    .expItem,
    .ageItem,
    .intelligenceItem {
      font-size: 14px;
      color: #999;
      text-align: center;
      margin: 10px 25px;
      cursor: pointer;
    }
    //修改的是el-input的样式
    //一种方法是设置最里层el-input__inner的背景色 外层的两级父元素设置为透明色
    //另一种方法是从el-select到el-input__inenr的背景色都设置为需要的颜色
    ::v-deep .el-select {
      text-align: center;
      margin: 6px 10px;
    }
    ::v-deep .el-select .el-input .el-input__inner {
      width: 140px;
      // width:100%;
      font-size: 14px;
      border: 0px;
      color: var(--color);
      border-radius: 0px;
    }
    .expSel{
      width: 105px;
    }

    //el-input聚焦的时候 外层的border会有一个样式
    ::v-deep .el-select .el-input.is-focus .el-input__inner {
      color: #ccc;
      border: 0px;
    }
    ::v-deep .el-select .el-input .el-select__caret.el-input__icon {
      transform: rotate(-45deg);
    }
    ::v-deep.el-select__caret.el-input__icon::before {
      content: "\e790";
      position: absolute;
      color: #ccc;
      width: 100%;
      top: 45%;
      right: -5%;
      transform: translate(-50%, -50%);
    }

    //修改总体选项的样式 最外层
    ::v-deep .el-select-dropdown {
      margin: 0px;
      border: 0px;
      border-radius: 0px;
    }

    //修改单个的选项的样式
    ::v-deep .el-select-dropdown__item {
      background-color: transparent;
      color: #fff;
    }

    //item选项的hover样式
    ::v-deep .el-select-dropdown__item:hover {
      color: #1592ea;
    }

    //修改的是下拉框选项内容上方的尖角
    ::v-deep .el-popper .popper__arrow::after {
      display: none;
    }
  }
}

.listBox {
  display: flex;
  justify-content: space-between;
  .listLeft {
    width: 900px;
    .personalList {
      height: 150px;
      background-color: #fff;

      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      .personalListTop {
        padding: 0 20px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 80px;
            height: 80px;
            object-fit: cover;
          }
          .name {
            margin-left: 16px;
            .nameTop {
              display: flex;
              align-items: center;
              margin-bottom: 16px;
              .peopleName {
                font-size: 18px;
                margin-right: 15px;
              }
              .pay {
                font-size: 20px;
                color: #ff8d19;
              }
            }
            .nameBottom {
              font-size: 14px;
              color: #666;
            }
          }
        }
        .right {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .contact {
            width: 120px;
            height: 36px;
            cursor: pointer;
            background-color: #1592ea;
            color: #fff;
            text-align: center;
            line-height: 36px;
            border-radius: 4px;
          }
          .city {
            font-size: 14px;
            color: #1592ea;
            display: flex;
            align-items: center;
          }
        }
      }
      .personalListBottom {
        padding: 0 20px;

        height: 40px;
        background-color: #fafafa;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tag {
          flex: 1;
        }
        .introduce {
          width: 550px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          color: #999;
          text-align: right;
        }
      }
    }
  }
  .listRight {
    width: 282px;
    .seeList,
    .recommend {
      background-color: #fff;
      margin-bottom: 16px;
      padding-bottom: 10px;
      .title {
        height: 48px;
        font-size: 14px;
        color: #029aff;
        line-height: 48px;
        padding: 0 20px 0 24px;
        border-bottom: 1px solid #e8e8e8;
      }
      .peopleList {
        padding: 0 20px 0 24px;
        .peopleItem:last-child {
          border-bottom: none;
        }
        .peopleItem {
          display: flex;
          padding: 10px 0;
          border-bottom: 1px solid #f2f2f2;
          img {
            width: 48px;
            height: 48px;
            margin-right: 20px;
          }
          .peopleInfo {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .name {
              display: flex;
              justify-content: space-between;
              span:nth-child(1) {
                font-size: 14px;
              }
              span:nth-child(2) {
                font-size: 12px;
                color: #666;
              }
            }
            .tag {
              font-size: 12px;
            }
          }
        }
      }
    }
    .recommend {
      background-color: #fff;
      .title {
        height: 48px;
        font-size: 14px;
        color: #029aff;
        line-height: 48px;
        padding: 0 20px 0 24px;
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }
}
</style>
